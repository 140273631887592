import { Courses } from './Courses';
import UserSession from './UserSession';

export default class MIAService {

    static instance = null;
    userSession = null;

    _keepAliveInterval = null;

    // Use this method to access MIAService
    static getInstance() {
        if (this.instance === null)
            this.instance = new MIAService();
        return this.instance;
    }

    isAuthenticated() { return this.userSession ? true : false; }

    // login (incl. role check and start keep-alive)
    login(username, password, rememberMe) {
        let params = {
            grant_type: "password",
            username: username,
            password: password,
            domain: "oncourse"
        };

        // Perform POST request
        return this.request('POST', process.env.REACT_APP_API_URL + "token", {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
        },
            this._encodeParameters(params)
        )
            .then((response) => {
                return response.json();
            })
            .then(async (jsonResp) => {
                if (jsonResp.error) {
                    console.warn(jsonResp.error);
                    return jsonResp;
                }

                await this.loginWithToken(jsonResp.refresh_token, jsonResp.access_token);

                if (rememberMe)
                    this._storeRefreshToken(this.userSession.refreshToken);

                // Return the result
                return jsonResp;
            });
    }

    loginWithToken = async (refreshToken, accessToken) => {
        return new Promise(async resolve => {
            if (accessToken != null)
                this._storeAccessToken(accessToken);
            else
                accessToken = this.getAccessToken();

            this.userSession = new UserSession();
            this.userSession.accessToken = accessToken;
            this.userSession.refreshToken = refreshToken;

            // Start set active course and keep-alive
            await this.setActiveCourse(Courses[0]);

            // Submit login event
            await this.submitEvent('AccountEvent', 'Login', Date.now())
                .then((response) => {
                    resolve(response);
                });
        });
    }

    logout(removeUserSession = true) {
        // Clear keep alive interval
        if (this._keepAliveInterval) {
            clearInterval(this._keepAliveInterval);
            this._keepAliveInterval = null;
        }

        if (removeUserSession)
            this.removeActiveUserSession();
    }

    // An authorized request (requests which can only be made with a valid token)
    authRequest(method, url, params = null, isRetry = false) {
        if (!this.userSession)
            throw new Error('User not authorized. No user session found.');

        if (method !== 'GET' && params) {
            params = JSON.stringify(params);
        }

        let reqData = {
            'method': method,
            'url': process.env.REACT_APP_API_URL + url,
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.userSession.accessToken
            },
            'params': params
        };

        let numUnauthorizedAttempts = 0;

        return new Promise(async resolve => {
            while (numUnauthorizedAttempts < 2) {                
                let req = await this.request(reqData.method, reqData.url, reqData.headers, reqData.params)
                    .catch(_ => _);
                
                if (this.userSession.refreshToken === null && req.status === 401)
                    this.logout(false);

                if (req.status === 401 && numUnauthorizedAttempts < 1) {
                    numUnauthorizedAttempts++;
                    await this._refreshToken(this.userSession.refreshToken);
                    reqData.headers.Authorization = 'Bearer ' + this.userSession.accessToken;
                }
                else
                    return resolve(req);
            }
        });
    }

    request(method, url, headers, body) {
        let reqConf = {
            method: method,
            headers: headers,
            mode: 'cors',
            cache: 'no-cache',
            referrer: 'no-referrer',
        }

        if (body)
            reqConf.body = body;

        return fetch(url, reqConf)
            .then(req => {
                console.log(method + ' ' + url + ':' + req.status + (body && '(' + body + ')'));
                if (req.status >= 500)
                    throw new Error("We're sorry, an error occured. Please, try again later.");
                return req;
            });
    }

    setActiveCourse = async (activeCourse) => {
        return new Promise(async resolve => {
            if (this.userSession.activeCourse !== activeCourse)
                await this.submitEvent('AccountEvent', 'CourseSelected', activeCourse.code, activeCourse.code);

            this.userSession.activeCourse = activeCourse;

            if (this._keepAliveInterval === null)
                this._startKeepAlive();

            resolve(true);
        });
    }

    setAvailableCourses(availableCourses) {
        this.userSession.availableCourses = availableCourses;
    }

    getRoles(course) {
        return this.authRequest('GET', 'course/' + encodeURIComponent(course.code) + '/roles')
    }

    getProfile() {
        return this.authRequest('GET', 'account');
    }

    submitEvent(category, type, parameter, assignmentCode = null) {
        let eventData = {
            'category': category,
            'type': type,
            'parameter': parameter
        };

        return this.authRequest('POST', 'course/' + encodeURIComponent(assignmentCode || this.userSession.activeCourse.code) + '/event', eventData);
    }

    getMemberships() {
        return this.authRequest('GET', 'account/memberships');
    }

    getCourseStats(courseCode = this.userSession.activeCourse.code) {
        return this.authRequest('GET', 'course/' + encodeURIComponent(courseCode) + '/stats/results/best');
    }

    getResourceGroup(courseCode = this.userSession.activeCourse.code) {
        return this.authRequest('GET', 'course/' + encodeURIComponent(courseCode));
    }

    getAssignment(assignmentCode) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/');
    }

    /*
    * Groups
    */

    getGroups() {
        return this.authRequest('GET', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/groups');
    }

    /*
    * Activities
    */

    getActivities() {
        return this.authRequest('GET', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/activities');
    }

    getActivitySession(activityId) {
        return this.authRequest('POST', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/activities/' + encodeURIComponent(activityId));
    }

    /*
    * Assignment Session
    */

    getAssignmentSession(assignmentCode, sessionId) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId));
    }

    createAssignmentSession(assignmentCode) {
        return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session');
    }

    getLatestAssignmentSession(assignmentCode) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/latest');
    }

    submitAssignmentSessionPosition(assignmentCode, sessionId, position) {
        return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId) + '/position', position);
    }

    finishAssignmentSession(assignmentCode, sessionId) {
        return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId) + '/finish');
    }

    /*
    * Assignment Session Answers
    */

    getAssignmentSessionAnswers(assignmentCode, sessionId) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId) + '/answers');
    }

    /* Example answers:
        [
            {
                questionId: 1,
                answerParts: [
                    {answerId: 1, answerPart: 'true'}
                ]
            }
        ]
    */
    submitAssignmentSessionAnswers(assignmentCode, sessionId, answers) {
        if (!Array.isArray(answers)) {
            answers = [answers];
        }

        if (sessionId === undefined) {
            return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/answers', answers);
        } else {
            return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId) + '/answers', answers);
        }

    }

    /*
    * Assignment Result
    */

    getAssignmentResults(assignmentCode) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/results');
    }

    submitAssignmentResult(assignmentCode, lineItemCode, result) {
        return this.authRequest('POST', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/results/' + encodeURIComponent(lineItemCode), result);
    }

    getAssignmentSessionResult(assignmentCode, sessionId) {
        return this.authRequest('GET', 'assignments/' + encodeURIComponent(assignmentCode) + '/session/' + encodeURIComponent(sessionId) + '/results');
    }

    /*
    * Report
    */

    getReport(reportCode, parameters = undefined) {
        return this.authRequest('POST', 'report/' + encodeURIComponent(reportCode), parameters);
    }

    /*
    * Parameters
    */

    getParameters(parameterNames = undefined) {
        let params = '';

        if ((Array.isArray(parameterNames) && parameterNames.length > 0) || (typeof (parameterNames) === 'object' && Object.keys(parameterNames).length > 0))
            params = '?' + Object.keys(parameterNames).map(function (val) { return { name: 'name', value: val }; });

        return this.authRequest('GET', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/parameters' + params);
    }

    submitParameters(parameters) {
        let parametersToSend = Object.keys(parameters).map((key) => {
            return { name: key, value: parameters[key] };
        });

        return this.authRequest('POST', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/parameters', parametersToSend);
    }

    submitParameter(parameterName, value) {
        let parametersToSend = [{
            name: parameterName,
            value: value
        }];

        return this.authRequest('POST', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/parameters', parametersToSend);
    }

    deleteParameters(parameterNames) {
        return this.authRequest('POST', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/parameters/delete', parameterNames);
    }

    /*
    * Methods used exclusively by this class instance
    */

    _encodeParameters(params) {
        if (!params)
            return null;

        return Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
    }

    _startKeepAlive() {
        // Submit event starting keep alive
        this.submitEvent('CourseEvent', 'Starting keep alive', Date.now())
            .then((response) => {
                if (!response.ok)
                    return response;

                // Clear the keep alive interval if set
                if (this._keepAliveInterval)
                    clearInterval(this._keepAliveInterval);

                // Start keep alive
                this._keepAliveInterval = setInterval(
                    () => {
                        this.authRequest('POST', 'course/' + encodeURIComponent(this.userSession.activeCourse.code) + '/event/keep-alive');
                    },
                    60000 // Every minute
                );
            });
    }

    // token actions

    _refreshToken = async (refreshToken) => {
        return this.request(
            'POST',
            process.env.REACT_APP_API_URL + "token",
            {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
            },
            this._encodeParameters({
                'grant_type': 'refresh_token',
                'refresh_token': refreshToken,
            })
        )
            .then(response => {
                if (response.ok)
                    return response.json();
                return false;
            })
            .then(res => {
                if (!res)
                    return;

                if (this.getRefreshToken())
                    this._storeRefreshToken(res.refresh_token);

                this._storeAccessToken(res.access_token);

                this.userSession.accessToken = res.access_token;
                this.userSession.refreshToken = res.refresh_token;
            });
    }

    // Storage of the access token

    _storeAccessToken = async (token) => {
        try {
            sessionStorage.setItem('access_token', token);
        } catch (e) {
            // saving error
        }
    }

    getAccessToken() {
        try {
            let value = sessionStorage.getItem('access_token');
            if (value !== null) {
                return value;
            }
        } catch (e) {
            // error reading value
            throw e;
        }

        return null;
    }

    _deleteAccessToken() {
        try {
            sessionStorage.removeItem('access_token');
        } catch (e) {
            throw e;
        }
    }

    // Storage of the refresh token

    _storeRefreshToken = async (token) => {
        try {
            localStorage.setItem('refresh_token', token);
        } catch (e) {
            // saving error
        }
    }

    getRefreshToken() {
        try {
            let value = localStorage.getItem('refresh_token');
            if (value !== null) {
                return value;
            }
        } catch (e) {
            // error reading value
            throw e;
        }

        return null;
    }

    _deleteRefreshToken() {
        try {
            localStorage.removeItem('refresh_token');
        } catch (e) {
            throw e;
        }
    }

    removeActiveUserSession() {
        this._deleteRefreshToken();
        this._deleteAccessToken();
        this.userSession = null;
    }

    // user actions

    passwordResetRequest(username) {
        return this.request('POST', process.env.REACT_APP_API_URL + 'account/PasswordResetRequest',
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            JSON.stringify({ UserName: username, Domain: 'oncourse' }));
    }

    resetPassword(token, password) {
        return this.request('POST', process.env.REACT_APP_API_URL + 'account/PasswordReset',
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            JSON.stringify({ Password: password, ValidationToken: token }));
    }

}