import React from 'react';
import LoadIndicator from '../../shared/LoadIndicator';
import MIAService from '../../../services/MIA/MIAService';
import PeriodicAnswersChart from '../teacher/chart/PeriodicAnswersChart';
import InstructionToggle from '../../shared/instructions/InstructionToggle';
import { primaryColor } from '../../../scss/styles.scss';

import DualAnswerChart from '../teacher/chart/DualAnswerChart';

class TeamReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teamId: this.props.teamId,
            teamName: this.props.teamName,
            error: null,
            loaded: false,
            questions: null,
            results: [],
        }
    }

    componentDidMount() {
        let team = MIAService.getInstance().userSession.groups[MIAService.getInstance().userSession.groups.length - 1];

        MIAService.getInstance().getReport('b5ca63ca-d880-4471-a9b0-c0dd18ebea0f', { teamId: this.state.teamId ? this.state.teamId : (team ? team.id : null) })
            .then(resp => resp.json())
            .then(result => {
                this.setState({
                    teamName: this.state.teamName ?  this.state.teamName : "Your team",
                    teamId: this.state.teamId ? this.state.teamId : (team ? team.id : null),
                    loaded: true,
                    results: this._parseResults(result.scores),
                    questions: this._parseQuestions(result.scores)
                });
            })
            .catch(error => this.setState({ error: error.message }));
    }

    render() {
        return (
            <div id="TeamReport">
                <div className="row">
                    <div className="col mb-3">
                        <div className="float-right">
                            <button id="print-button" className="btn btn-primary btn-lg mr-2" onClick={() => window.print()}>
                                <span>print</span>
                            </button>
                            <InstructionToggle description="Save the Team Report as a PDF by clicking the print button and selecting 'Save to PDF' as your destination/printer." />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    { this.state.loaded ? (
                        (this.state.results.length > 0 ? 
                            (this.state.results.map((a, i) => 
                                <div key={i} className="col-lg-6">
                                    <div className="mb-5">
                                        <h2>{a.AssignmentName}</h2>
                                        <hr/>
                                        <div className="list-group list-group-flush">
                                        {a.QuestionResults.map((q, f) =>
                                            <div key={f} className="list-group-item">
                                                <p className="title mt-1">{f + 1}: {q.QuestionTitle}</p>
                                                <blockquote className="font-weight-light mb-3" dangerouslySetInnerHTML={{ __html: q.QuestionText }} />
                                                <PeriodicAnswersChart dataSets={this._parseChartScores(q.Scores)} legend={{ display: true }} />
                                                { q.CollectionId && f % 2 === 1 &&
                                                    <div>
                                                        <DualAnswerChart
                                                            showIndividualData={false}
                                                            collectionId={q.CollectionId}
                                                            questions={this.state.questions}
                                                            teamId={this.state.teamId}
                                                            teamName={this.state.teamName}
                                                            questionId={q.QuestionId} />
                                                    </div>}
                                                { i=== 0 && f === 2 && <div className="print-page-sep"></div>}
                                                { i=== 1 && f === 3 && <div className="print-page-sep"></div>}
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            )) : (
                            <div className="col-lg-8">
                                <p className="alert alert-primary">There are no results yet.</p>
                            </div>
                        ))
                    ) : (
                        <LoadIndicator />
                    )}
                </div>
            </div >
        );
    }

    _parseResults(results) {
        let data = [];

        for (let i = 0; i < results.length; i++) {
            let row = results[i];

            let assignmentIndex = -1;

            for (let f = 0; f < data.length; f++) {
                if (data[f].AssignmentName === row.AssignmentName)
                    assignmentIndex = f;
            }

            if (assignmentIndex < 0) {
                data.push(
                    {
                        AssignmentName: row.AssignmentName,
                        QuestionResults: []
                    }
                )
                assignmentIndex = data.length - 1;
            }

            let questionIndex = -1;

            for (let f = 0; f < data[assignmentIndex].QuestionResults.length; f++) {
                if (data[assignmentIndex].QuestionResults[f].QuestionId === row.QuestionId)
                    questionIndex = f;
            }

            if (questionIndex > -1) {
                data[assignmentIndex].QuestionResults[questionIndex].Scores.push({ Score: row.Score, TeamAverage: row.TeamAverage });
            } else {
                data[assignmentIndex].QuestionResults.push({
                    QuestionId: row.QuestionId,
                    QuestionTitle: row.QuestionTitle,
                    QuestionText: row.QuestionText,
                    CollectionId: row.CollectionId,
                    Scores: [
                        {
                            Score: row.Score,
                            TeamAverage: row.TeamAverage
                        }
                    ],
                });
            }
        }
        
        return data;
    }

    _parseQuestions(result) {
        let q = [];
        result.forEach(r => {
            if (!q.find(qr => qr.Id === r.QuestionId))
                q.push({Id: r.QuestionId, Collection: r.CollectionId, QuestionText: r.QuestionText });
        });
        return q;
    }

    _parseChartScores(scores) {
        let periodicData = [];
        let avgData = [];

        scores.forEach((s, i) => {
            avgData.push({
                x: i + 1,
                y: s.TeamAverage,
            })
            periodicData.push({
                x: i + 1,
                y: s.Score,
            })
        });
        
        let labels = [];

        for (let i = 1; i <= periodicData.length; i++)
            labels.push(i);

        let periodicDataSets = {
            labels: labels,
            datasets: [
                {
                    label: this.state.teamName,
                    showLine: true,
                    borderColor: primaryColor,
                    pointBackgroundColor: primaryColor,
                    tension: 0,
                    fill: false,
                    data: periodicData,
                },{
                    label: 'Average',
                    showLine: true,
                    borderColor: 'lightgray',
                    pointBackgroundColor: 'lightgray',
                    tension: 0,
                    fill: false,
                    data: avgData,
                }
            ]
        };

        return periodicDataSets;
    }

}

export default TeamReport;